<template>
  <v-container fluid fill-height class="login-container">
    <v-col cols="12" lg="12" class="d-flex align-center">
      <v-container>
        <div
          class="d-flex align-center justify-center"
          style="min-height: calc(100vh - 148px)"
        >
          <v-row justify="center">
            <v-col cols="12" md="12">
              <v-card elevation="0" class="loginBox">
                <v-card elevation="2">
                  <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark>
                    <v-btn
                      icon
                      class="hidden-xs-only text-white"
                      @click="redirectToLoginPage()"
                    >
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>

                    <span class="text-h6">Reset Password</span>
                  </v-toolbar>
                  <v-card-text class="pb-6 px-6 pt-0">
                    <v-form class="mt-7">
                      <AError :api="api"></AError>

                      <div class="">
                        <v-label class="text-overline">Email Address</v-label>
                        <v-text-field
                          required
                          outlined
                          dense
                          v-model="form.email"
                          class="mt-2"
                          @keydown.enter.prevent
                        ></v-text-field>
                      </div>

                      <div class="d-flex justify-end">
                        <v-btn
                          :loading="api.isLoading"
                          :color="this.hrdcData.HRDC_BLUE_THEME_COLOR"
                          class="text-decoration-none text-white"
                          @click="fetch()"
                          >Send Recovery Email</v-btn
                        >
                      </div>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-container>
</template>

<script>
import Api from "@/objects/api";
import HrdcData from "@/services/hrdc/objects/globalData";

export default {
  data: () => ({
    form: {
      email: null,
    },
    api: new Api(),
    hrdcData: new HrdcData(),
  }),
  created() {
    this.api.setMethod(`POST`);
    this.api.setUrl(`${this.$service.sso}/v1/en/auth/login/password/reset`);
    this.api.setCallbackCompleted((response) => {
      if (response.status) {
        // Redirect back to login page.
        this.redirectToLoginPage(response.message);
      }
    });
  },
  methods: {
    fetch() {
      this.api.setParams(this.form);
      this.api.fetch();
    },
    redirectToLoginPage(message = null) {
      this.$router.push({
        name: "PageGuestHome",
        params: {
          message,
        },
      });
    },
  },
};
</script>

<style scoped>
.login-title {
  background-image: linear-gradient(
    to right,
    rgba(6, 48, 88),
    rgba(240, 81, 35)
  );
}
.login-container {
  background-color: #fafafb;
  min-height: 100vh; /* Optional: Set minimum height to cover the entire viewport */
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginBox {
  max-width: 475px;
  margin: 0 auto;
}
</style>
